<template>
  <div>
    <v-row class="container-margin">
      <v-col cols="4">
        <div class="content-container">
          <v-col cols="12">
            <h2 class="information-title">Параметры</h2>
          </v-col>
          <div class="horizontal-line"></div>
          <v-col cols="12" class="d-flex justify-space-between mb-1">
            <p class="information-title-text-special">Программы лояльности</p>
            <p v-if="isUsingBonus" class="information-text-green">
              Используется
            </p>
            <p v-if="!isUsingBonus" class="information-text-red">
              Не используется
            </p>
          </v-col>
          <v-col cols="12" class="">
            <p class="information-title-text-special">
              Тип программы лояльности
            </p>
            <p class="information-text">
              {{ this.bonusGroupName !== null ? this.bonusGroupName : "null" }}
            </p>
          </v-col>
          <div class="horizontal-line mt-3 mb-4"></div>
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <div>
              <p class="information-title-text-special">Доставка</p>
              {{
              isDeliveryAvailable ? "Доставка включена" : "Доставка выключена"
            }}
            </div>
            <div class="">
              <input 
              type="checkbox" 
              hidden="hidden" id="discount" 
              v-model="isDeliveryAvailable"
              @change="onInputChange" />

              <label class="switch" for="discount"></label>
            </div>
          </v-col>
          <div class="horizontal-line mt-3 mb-4"></div>

          <v-col cols="12" class="">
            <p class="information-title-text-special">Юр лицо</p>
            <p class="information-text">
              {{ this.entityName }}
            </p>
          </v-col>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="content-container">
          <v-col cols="12">
            <h2 class="information-title">Информация</h2>
          </v-col>
          <div class="horizontal-line"></div>
          <v-col cols="12" class="pb-0">
            <p style="margin-left: 5px" class="information-title-text">
              Описание ресторана<span v-if="redStarDescription" class="red-dot">*</span>
            </p>
          </v-col>
          <v-col cols="12" class="px-2 pt-0 pb-0">
            <textarea @click="clickStarDescription" @blur="handleBlurStarDescription" style="width: 100%"
              class="col-description" v-model="descAdress" @input="onInputChange" ref="descAdressTextarea"
              :placeholder="isdescAdressFocused ? '' : textareaPlaceholder"></textarea>
          </v-col>
          <v-col cols="12" class="py-0">
            <p style="margin-left: 5px" class="col-title">
              Адрес<span v-if="redStarAdress" class="red-dot">*</span>
            </p>
          </v-col>
          <v-col cols="12" class="px-2 pt-0 pb-2">
            <input @click="clickStarAdress" @blur="handleBlurStarAdress" style="width: 100%" class="col-description"
              v-model="infoAdress" ref="infoAdressTextarea"
              :placeholder="isinfoAdressFocused ? '' : textareaPlaceholder" @input="onInputChange" />
          </v-col>

          <v-col cols="12" class="py-0">
            <p style="margin-left: 5px" class="information-title-text">
              Номер телефона для связи клиентов
            </p>
          </v-col>
          <v-col cols="12" class="px-2 pt-0 pb-2">
            <input style="width: 100%" class="col-description" v-model="phone" ref="phoneTextarea"
              v-mask="'+7 (###) ###-##-##'" type="tel" :placeholder="isphoneFocused ? '' : textareaPlaceholder"
              @input="onInputChange" />
          </v-col>

          <v-col cols="12" class="py-0">
            <p style="margin-left: 5px" class="col-title">
              Ссылка на инстаграмм
            </p>
          </v-col>
          <v-col cols="12" class="px-2 pt-0 pb-0">
            <input style="width: 100%" class="col-description" v-model="instagram" ref="instagramTextarea"
              :placeholder="isinstagramFocused ? '' : textareaPlaceholder" @input="onInputChange" />
          </v-col>
          <div style="margin-top: 17.5px; margin-bottom: 18.5px" class="horizontal-line"></div>

          <v-col cols="12" class="py-0">
            <p style="margin-left: 5px" class="information-title-text-special mb-0">
              Режим работы
            </p>
          </v-col>
          <div class="d-flex">
            <v-col cols="4" class="ml-1">
              <p class="information-title-text">Время начало</p>
              <input style="width: 132px" type="text" class="col-description" v-model="timeWorkStart"
                placeholder="00:00" maxlength="5" v-mask="'##:##'" @input="onInputChange" />
            </v-col>
            <v-col cols="4" class="">
              <p class="information-title-text">Время окончания</p>
              <input style="width: 132px" type="text" class="col-description" v-model="timeWorkEnd" placeholder="00:00"
                maxlength="5" v-mask="'##:##'" @input="onInputChange" />
            </v-col>
          </div>
          <div style="" class="horizontal-line mb-5"></div>
          <v-col cols="12" class="py-0">
            <p style="margin-left: 5px" class="information-title-text-special mb-0">
              Время доставки
            </p>
          </v-col>
          <div class="d-flex" style="margin-bottom: 28px">
            <v-col cols="4" class="ml-1 pb-0">
              <p class="information-title-text">Время начало</p>
              <input style="width: 132px" type="text" class="col-description" v-model="timeDeliveryStart"
                placeholder="00:00" maxlength="5" v-mask="'##:##'" @input="onInputChange" />
            </v-col>
            <v-col cols="4" class="pb-0">
              <p class="information-title-text">Время окончания</p>
              <input style="width: 132px" type="text" class="col-description" v-model="timeDeliveryEnd"
                placeholder="00:00" maxlength="5" v-mask="'##:##'" @input="onInputChange" />
            </v-col>
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="content-container">
          <v-col cols="12">
            <h2 class="information-title">Логотип и Картинка</h2>
          </v-col>
          <v-col cols="12" class="logo-flex">
            <div class="d-flex">
              <p class="mb-0 logo-dot">Логотип</p>
              <span class="red-dot">*</span>
            </div>
            <div class="d-flex">
              <div v-if="uploadedImageLogo" class="uploaded-image-container">
                <img :src="uploadedImageLogo" alt="Uploaded Image" class="uploaded-image-logo" />
              </div>
              <input type="file" accept="image/*" id="img-input-1" @change="uploadImageLogo" style="display: none" />
              <label for="img-input-1" class="custom-file-upload">
                <img src="../../assets/img/input-logo.svg" alt="" />
              </label>
            </div>
          </v-col>
          <div class="horizontal-line"></div>
          <v-col cols="12" class="logo-flex">
            <div class="d-flex">
              <p class="mb-0 logo-dot-2">Header логотип</p>
              <span class="red-dot">*</span>
            </div>
            <div class="d-flex">
              <div v-if="uploadedImageHeader" class="uploaded-image-container">
                <img :src="uploadedImageHeader" alt="Uploaded Image" class="uploaded-image-header" />
              </div>
              <input type="file" accept="image/*" id="img-input-2" @change="uploadImageHeader" style="display: none" />
              <label for="img-input-2" class="custom-file-upload">
                <img src="../../assets/img/input-logo.svg" alt="" />
              </label>
            </div>
          </v-col>
          <div class="horizontal-line"></div>
          <v-col cols="12" class="logo-flex">
            <div class="d-flex">
              <p class="mb-0 logo-dot-3">Фото ресторана</p>
              <span class="red-dot">*</span>
            </div>
            <div class="d-flex">
              <div v-if="uploadedImagePhotoRest" class="uploaded-image-container">
                <img :src="uploadedImagePhotoRest" alt="Uploaded Image" class="uploaded-image-photo-rest" />
              </div>
              <input type="file" accept="image/*" id="img-input-3" @change="uploadImagePhotoRest"
                style="display: none" />
              <label for="img-input-3" class="custom-file-upload">
                <img src="../../assets/img/input-logo.svg" alt="" />
              </label>
            </div>
          </v-col>
          <div class="horizontal-line margin-logo-divider"></div>
        </div>
        <div class="content-container" style="margin-top: 32px">
          <v-col cols="12">
            <h2 class="information-title">Настройки</h2>
          </v-col>
          <div class="horizontal-line"></div>
          <v-col cols="12" class="pb-1 pt-4">
            <p style="" class="information-title-text">
              Минимальная сумма заказа
            </p>
          </v-col>
          <v-col cols="12" class="px-2 pt-0 pb-2">
            <input style="width: 100%" class="col-description" v-model="minSumOrder" ref="minSumOrderTextarea"
              @input="onInputChange" placeholder="0 ₸" type="number" />
          </v-col>

          <v-col cols="12" class="pb-1 pt-4">
            <p style="" class="information-title-text">
              Наша коммисия с каждого заказа( надо добавить методы их пока в
              модели нет и выпилить formatOurCommissionInput и
              clearCurrencySymbolOurCommission)
            </p>
          </v-col>
          <v-col cols="12" class="px-2 pt-0 pb-2">
            <input style="width: 100%" class="col-description" v-model="ourCommission" ref="ourCommissionTextarea"
              input="formatOurCommissionInput('ourCommission')" @keydown="clearCurrencySymbolOurCommission($event)"
              placeholder="0 ₸" />
          </v-col>
        </div>
      </v-col>
    </v-row>
  </div>
</template>



<style scoped>
.special-border:focus {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}

textarea::-webkit-scrollbar {
  width: 5px;
  /* ширина полосы прокрутки */
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  /* цвет полосы прокрутки */
  border-radius: 2px;
  /* закругление углов полосы прокрутки */
}

textarea::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* цвет фона дорожки полосы прокрутки */
}

input:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}

input:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

.full-text-input,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

textarea:focus {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}

textarea:hover {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

.border-textarea {
  box-sizing: border-box;
  border: 1px solid rgb(212, 212, 225);
  border-radius: 5px;
  background: rgb(255, 255, 255);
}

.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 20px;
  background: #eaeaea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}

.switch::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked+.switch {
  background: #72da67;
}

input:checked+.switch::before {
  left: 27px;
  background: #fff;
}

input:checked+.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.information-text {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}

.information-title-text {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}

.information-title-text-special {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
}

.information-text-green {
  color: rgb(0, 184, 0);
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}

.information-text-red {
  color: red;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
}

.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}

.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.margin-logo-divider {
  margin-bottom: 48px;
}

.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-file-upload {
  cursor: pointer;
}

.logo-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded-image-logo {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.uploaded-image-header {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.uploaded-image-photo-rest {
  max-width: 100px;
  /* Adjust this value as needed */
  max-height: 100px;
  /* Adjust this value as needed */
  margin-right: 21px;
}

.work-margin-img {
  margin-bottom: 15px;
}

.work-img {
  width: 22px;
  height: 22px;
}

.col-img {
  flex-direction: column-reverse;
  display: flex;
}

.work-margin {
  padding-bottom: 15px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}

.tel-pointer {
  cursor: pointer;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}

.col-description {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  min-height: 20px;
  /* Минимальная высота для textarea */
  padding: 10px;
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}

.information-title {
  color: rgb(50, 60, 77);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}

.content-container {
  border-radius: 8px;
  background: rgb(255, 255, 255);
  max-width: 533px;
  padding: 10px 10px 20px 10px;
}

.container-margin {
  margin: auto;
}
</style>

<script>
import { mask } from "vue-the-mask";
export default {
  name: "Summary",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },
  data() {
    return {
      originalData: {},
      entityName: "",
      ourCommission: "",
      minSumOrder: "",
      timeDeliveryStart: "",
      timeDeliveryEnd: "",
      timeWorkStart: "",
      timeWorkEnd: "",
      isDeliveryAvailable: "",
      bonusGroupName: null,
      isUsingBonus: "",
      selectedImage: null,
      uploadedImageLogo: "",
      uploadedImageHeader: "",
      uploadedImagePhotoRest: "",
      isPlaceholderHidden: false,
      isLegalAdressFocused: false,
      isdescAdressFocused: false,
      isActualAdressFocused: false,
      isBINFocused: false,
      isIIKFocused: false,
      isBIKFocused: false,
      isBankFocused: false,
      isFIOHEADFocused: false,
      isinfoAdressFocused: false,
      isphoneFocused: false,
      isinstagramFocused: false,
      isminSumOrderFocused: false,
      legalAdress: "",
      actualAdress: "",
      BIN: "",
      IIK: "",
      BIK: "",
      Bank: "",
      FIOHEAD: "",
      descAdress: "",
      infoAdress: "",
      phone: "",
      instagram: "",
    };
  },
  created() {
    this.originalData = this.responseData;
    this.entityName = this.responseData.account.name;
    this.minSumOrder = this.responseData.minOrderAmount;

    this.timeDeliveryEnd = this.responseData.restaurantDelivery.deliveryEndTime
      .split(":")
      .slice(0, 2)
      .join(":");

    this.timeDeliveryStart =
      this.responseData.restaurantDelivery.deliveryStartTime
        .split(":")
        .slice(0, 2)
        .join(":");
    this.timeWorkEnd = this.responseData.workingHoursEndTime
      .split(":")
      .slice(0, 2)
      .join(":");
    this.timeWorkStart = this.responseData.workingHoursStartTime
      .split(":")
      .slice(0, 2)
      .join(":");

    this.isDeliveryAvailable = this.responseData.restaurantDelivery.isDeliveryAvailable;

    // this.originalData.isDeliveryAvailable = this.isDeliveryAvailable;
    this.bonusGroupName = this.responseData.bonusGroup.name;
    this.isUsingBonus = this.responseData.isUsingBonus;
    this.descAdress = this.responseData.description;
    this.infoAdress = this.responseData.address;
    this.phone = this.responseData.phoneNumber;
    this.instagram = this.responseData.instagramUrl;
    this.uploadedImageLogo =
      process.env.VUE_APP_API_HOST + this.responseData.logo.imagePath;
    this.uploadedImageHeader =
      process.env.VUE_APP_API_HOST + this.responseData.headerLogo.imagePath;
    this.uploadedImagePhotoRest =
      process.env.VUE_APP_API_HOST +
      this.responseData.backgroundImage.imagePath;
    this.legalAdress = this.responseData.address;
    this.actualAdress = this.responseData.address;
    this.FIOHEAD = this.responseData.account.name;
  },
  methods: {
    clickStarDescription() {
      this.redStarDescription = true;
    },
    clickStarAdress() {
      this.redStarAdress = true;
    },
    handleBlurStarDescription() {
      this.redStarDescription = false;
    },
    handleBlurStarAdress() {
      this.redStarAdress = false;
    },
    logDeliveryAvailability() {
      console.log("Delivery availability changed to:", this.isDeliveryAvailable);
      console.log("Delivery equals to :", this.isDeliveryAvailable !== this.originalData.restaurantDelivery.isDeliveryAvailable);
    },
    callWork() {
      if (this.phone) {
        window.location.href = `tel:${this.phone}`;
      }
    },
    onInputChange() {
      // Вызывается при изменении любого из input-полей
      this.$emit("data-changed", this.isDataChanged());
    },

    isDataChanged() {
      const conditionsToCheck = [
        this.descAdress !== this.originalData.description ||
        this.infoAdress !== this.originalData.address ||
        this.timeDeliveryEnd !==
        this.responseData.restaurantDelivery.deliveryEndTime
          .split(":")
          .slice(0, 2)
          .join(":") ||
        this.timeDeliveryStart !==
        this.responseData.restaurantDelivery.deliveryStartTime
          .split(":")
          .slice(0, 2)
          .join(":") ||
        this.timeWorkEnd !==
        this.responseData.workingHoursEndTime
          .split(":")
          .slice(0, 2)
          .join(":") ||
        this.timeWorkStart !==
        this.responseData.workingHoursStartTime
          .split(":")
          .slice(0, 2)
          .join(":") ||
        this.instagram !== this.responseData.instagramUrl ||
        this.phone.replace(/[^+\d]/g, "") !== this.responseData.phoneNumber ||
        this.isDeliveryAvailable !== this.originalData.restaurantDelivery.isDeliveryAvailable,
        // Добавьте другие условия по необходимости
      ];

      for (const condition of conditionsToCheck) {
        if (!condition) {
          // Если хотя бы одно условие ложно, возвращаем false
          return false;
        }
      }

      // Если все условия истинны, возвращаем true
      return true;
    },

    toggleSwitch() {
      // Меняем значение isDeliveryAvailable при клике на "switch"
      //this.isDeliveryAvailable = !this.isDeliveryAvailable;
      console.log(this.isDeliveryAvailable);
      console.log(this.originalData.isDeliveryAvailable);

      // Добавляем дополнительный вывод для проверки
      console.log("toggleSwitch method has been called");
    },

    uploadImageLogo(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImageLogo = URL.createObjectURL(file);
      }
    },
    uploadImageHeader(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImageHeader = URL.createObjectURL(file);
      }
    },
    uploadImagePhotoRest(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedImage = file;
        this.uploadedImagePhotoRest = URL.createObjectURL(file);
      }
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    adjustTextareaAdress(infoAdressTextarea) {
      const textarea = this.$refs[infoAdressTextarea];
      const minHeight = 30; // ваша минимальная высота в пикселях
      textarea.style.minHeight = `${minHeight}px !important`; // принудительно устанавливаем minHeight
      textarea.style.height = "auto";
      textarea.style.height = Math.max(textarea.scrollHeight, minHeight) + "px";
    },

    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    formatminOrderInput() {
      // Удаляем все символы, кроме цифр
      let value = this.minSumOrder.replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this.minSumOrder = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      //value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем двоеточие в конце
      // value += "₸";

      // Обновляем значение модели
      //this.minSumOrder = value;
    },
    clearCurrencySymbolMinOrder(event) {
      if (event.key === "Backspace" && this.minSumOrder.length > 0) {
        this.minSumOrder = this.minSumOrder.slice(0, -1); // Удаляем последний символ
      }
    },
    formatOurCommissionInput() {
      // Удаляем все символы, кроме цифр
      let value = this.ourCommission.replace(/\D/g, "");

      // Если строка пуста, не выполняем форматирование
      if (value === "") {
        this.ourCommission = "";
        return;
      }

      // Форматируем число с пробелами в качестве разделителя тысяч
      value = parseInt(value).toLocaleString("ru-RU").replace(/,/g, " ");

      // Добавляем двоеточие в конце
      value += "₸";

      // Обновляем значение модели
      this.ourCommission = value;
    },
    clearCurrencySymbolOurCommission(event) {
      if (event.key === "Backspace" && this.ourCommission.length > 0) {
        this.ourCommission = this.ourCommission.slice(0, -1); // Удаляем последний символ
      }
    },
  },
  watch: {
    BIN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("BINTextarea");
      });
    },
    IIK() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("IIKTextarea");
      });
    },
    BIK() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("BIKTextarea");
      });
    },
    Bank() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("BankTextarea");
      });
    },
    FIOHEAD() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("FIOHEADTextarea");
      });
    },
    actualAdress() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("actualTextarea");
      });
    },
    legalAdress() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("legalTextarea");
      });
    },
    descAdress() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descAdressTextarea");
      });
    },
    infoAdress() {
      this.$nextTick(() => {
        this.adjustTextareaAdress("infoAdressTextarea");
      });
    },
    phone() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("phoneTextarea");
      });
    },
    minSumOrder() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("minSumOrderTextarea");
      });
    },

    instagram() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("instagramTextarea");
      });
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },
};
</script>